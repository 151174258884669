import React, { useState } from "react";
import Navbar from "./Navbar";
import "./About.css";
import { CarouselData } from "./CarouselData";
import background1 from "../img/coffee-beans1.png";
import { motion } from "framer-motion";
import up from "../coffeeflavour/vanilla.png";
import down from "../coffeeflavour/irish.png";
import down2 from "../coffeeflavour/raspberry.png";
import "./Products.css";
import Card from "./Cards/Card";
import Card2 from "./Cards/Card2";
import Card3 from "./Cards/Card3";
import Card4 from "./Cards/Card4";
import Footer from "./Footer"
const Products = () => {
  const transition = { type: "spring", duration: 3 };
  const [selected, setSelected] = useState(0);
  const tLength = CarouselData.length;

  return (
    <div className="fontss">
      <Navbar />
      <body>
        <div style={{ padding: "0", margin: "0" }}></div>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Montserrat:400,700"
        />

        <section>
          <div
            id="parallax"
            class="parallax-item text1"
            style={{ marginTop: "-112px" }}
          >
            <h2
            className="anti"
            
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Antidote
            </h2>
          </div>

          <div class="parallax-item">
            <div className="fontss">
              <div
                className="aboutbg1"
                //  style={{backgroundColor:'black'}}
              >
                <div className="nav" style={{ backgroundColor: "black" }}></div>
                <div
                  className="aboutflex"
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div className="image-coff" style={{ width: "15%" }}>
                    <img
                      src={require("../img/coffee-beans1.png")}
                      alt=""
                      className="beans"
                      style={{ width: "163%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "140%",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <div className="left-hh" style={{ width: "90%" }}>
                      <h1
                        className="heading8"
                        style={{ letterSpacing: "5px", fontSize: "xxx-large" }}
                      >
                        AntidotÉ
                        {/* <span>®</span> */}
                        <br />
                        <span>
                          <span style={{ color: "rgb(199, 161, 122)" }}>
                            {CarouselData[selected].name}
                          </span>{" "}
                        </span>{" "}
                      </h1>

                      <div className="font66" style={{
                         fontSize: "26px",fontWeight: "100" }}>
                        <motion.span
                          key={selected}
                          transition={transition}
                          initial={{ opacity: 0, x: 100 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: -100 }}
                        >
                          {CarouselData[selected].review}
                        </motion.span>
                      </div>
                    </div>
                  </div>

                  <div className="right-hh bggg " style={{ display: "flex" }}>
                    <motion.img
                      //  data-aos-duration="2000"
                      key={selected}
                      transition={transition}
                      initial={{ opacity: 0, x: 100 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -100 }}
                      className="imggg"
                      src={CarouselData[selected].image}
                      alt=""
                    />
                    <div
                      className="bgarrow  arrow bg56"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "30px",
                        
                        marginBottom:'30px'
                        
                      }}
                    >
                      {/* <div></div> */}
                      <h1 
                        data-aos="fade-left" 
                        data-aos-duration="3000"
                      style={{marginTop:"2px",fontWeight:'100',whiteSpace:'pre'}} >Similar Flavours</h1>
                      <div
                        className="boxxx"
                        style={{ width:'30%', display: "flex",justifyContent: "center" }}
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1000"
                      >
                        <img
                          style={{ width: "90px",marginTop:'-20px' }}
                          onClick={() => {
                            selected === 0
                              ? setSelected(tLength - 1)
                              : setSelected((prev) => prev - 1);
                          }}
                          src={up}
                          alt=""
                        />
                      </div>

                      <div
                        className="boxxx"
                        style={{ width:'30%', display: "flex",justifyContent: "center" }}
                        data-aos="fade-right"
                        data-aos-easing="linear"
                        data-aos-duration="1000"
                      >
                        <img
                          style={{ width: "90px" }}
                          onClick={() => {
                            selected === 0
                              ? setSelected(tLength - 1)
                              : setSelected((prev) => prev - 1);
                          }}
                          src={down}
                          alt=""
                        />
                      </div>
                      <div
                        className="boxxx"
                        style={{ width:'30%', display: "flex",justifyContent: "center" }}
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration="1000"
                      >
                        <img
                          style={{ width: "90px" }}
                          onClick={() => {
                            selected === 0
                              ? setSelected(tLength - 1)
                              : setSelected((prev) => prev - 1);
                          }}
                          src={down2}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flavour-heading">
                    <h1>similar flavour</h1>
                  </div>

                  <div className="mediaQ">
                    <img
                       data-aos="fade-left"
                      //  data-aos-easing="linear"
                       data-aos-duration="1000"
                      onClick={() => {
                        selected === 0
                          ? setSelected(tLength - 1)
                          : setSelected((prev) => prev - 1);
                      }}
                      src={up}
                      alt=""
                    />

                    <img
                      data-aos="fade-down"
                      // data-aos-easing="linear"
                      data-aos-duration="1500"
                      onClick={() => {
                        selected === 0
                          ? setSelected(tLength - 1)
                          : setSelected((prev) => prev - 1);
                      }}
                      src={down}
                      alt=""
                    />
                    <img
                      data-aos="fade-right"
                      // data-aos-easing="linear"
                      data-aos-duration="1000"
                      onClick={() => {
                        selected === 0
                          ? setSelected(tLength - 1)
                          : setSelected((prev) => prev - 1);
                      }}
                      src={down2}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="parallax-item">
            <div
              className="roww2"
              style={{
                display: "flex",
                gap: "2rem",
                marginTop: "50px",
                justifyContent: "center",
                margin: "30px",
              }}
            >
              <div>
                {" "}
                <img
                  src={require("../img/flex1.jpg")}
                  alt=""
                  style={{ width: "517px" }}
                  className="flex3"
                />{" "}
              </div>
              <div>
                {" "}
                <img
                  src={require("../img/flex2.jpg")}
                  style={{ width: "517px" }}
                  className="flex4"
                />
              </div>
            </div>
            <div
              className="roww2"
              style={{
                display: "flex",
                gap: "2rem",
                marginTop: "50px",
                justifyContent: "center",
                margin: "30px",
              }}
            >
              <div>
                {" "}
                <img
                  src={require("../img/flex3.jpg")}
                  alt=""
                  style={{ width: "517px" }}
                  className="flex3"
                />{" "}
              </div>
              <div>
                {" "}
                <img
                  src={require("../img/flex4.jpg")}
                  style={{ width: "517px" }}
                  className="flex4"
                />
              </div>
            </div>
          </div> */}
          {/* <div class="parallax-item"></div> */}
          <div
            class="parallax-item cards"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Card />
            <Card2 />
      
          </div>
          <div
            className="cards2 parallax-item"
            style={{ display: "flex", justifyContent: "space-evenly" ,marginTop:'10px' }}
          >
            <Card3 />
            <Card4 />
          </div>
        </section>
      </body>

      <section className="blue">


        <div className="fll" >
        <div className="st">
        <img src={require('../img2/pro1.jpg')} alt=""  style={{borderRadius: "14px"}}/>
       <h1 className="h11">Hand Picked</h1>
       <p className="p11">Our coffees are made from beans sourced from India’s finest estates, handpicked when they are in their ideal state of ripeness ensuring the best cup of coffee for you.

</p>

        </div>
        <div className="st" >
        <img src={require('../img2/pro2.jpg')} alt=""  style={{borderRadius: "14px"}}/>
<h1 className="h11">The Beans</h1>
<p className="p11">Only the choicest of beans make it to the selection of coffee we use to make our signature blends. We use Grade A and above beans for all our coffee.</p>

        </div>
        <div className="st" >
        <img  src={require('../img2/pro3.jpg')} alt=""  style={{borderRadius: "14px"}}/>
<h1 className="h11">Artisanal Coffee</h1>
<p className="p11"> Our in house barista oversees the entire operation from processing to roasting, grinding and packaging. </p>

        </div>
       
        </div>
      </section>
<section >
<div>
  <Footer/>
 </div>

</section>
     
    </div>
  );
};

export default Products;



